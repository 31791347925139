.map-add-pin {
	position: absolute;
	right: 10px;
	bottom: 50%;
	background-color: rgba(255, 255, 255, 1);
	width: 55px;
	height: 55px;
	border-radius: 50%;
	cursor: pointer;
	display: grid;
	box-shadow: 2px 2px 2px rgba(64, 64, 64, .12); 
	border: 1px solid rgba(233, 12, 131, 1);
}
.map-add-pin:hover {
	background-color: rgba(233, 12, 131, 1);
	border: 1px solid rgba(255, 255, 255, 1);
	img {
		filter: brightness(0) invert(1);	
	}
	line {
		stroke: rgba(255, 255, 255, 1);
	}
}
.map-pin-wrapper {
	position: relative;
}
.map-pin-image {
	position: absolute;
	width: 21px;
	left: 15.5px;
	bottom: 11px;
}