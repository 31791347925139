@import 'variables';

.race-wrapper {
	display: grid; 
	align-content: center; 
	grid-gap: 20px; 
	justify-items: center;
}
.race {
	display: grid;
	justify-items: center;
	grid-template-rows: 1fr 2fr 2fr;
	grid-gap: 20px;
	background-color: $light-bg;
	overflow: hidden;
}
.race-title {
	color: $primary;
	font-size: 1.4em;
	font-weight: 600;
}
.race-subtitle {
	color: $light-gray;
	font-size: 1.2em;
	font-weight: 600;
}
.race-items {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	text-align: center;
	color: $light-gray;
	font-weight: 700;
	font-size: 1.4em;
	div	{
		background-color: rgba(255, 255, 255, 1);
		padding: 15px;
		border-radius: 15px;
		border: $light-border;
		cursor: pointer;
	}
	div:hover {
		color: rgba(255, 255, 255, 1);
		background-color: $primary;
	}
}

// mobile-style
@media screen and (max-width: 768px) {
	.race {
		grid-template-rows: min-content 2fr 2fr;		
	}
	.race-items {
		grid-template-columns: 1fr 1fr;
		font-size: 1em;
		div	{
			padding: 10px;
			border-radius: 10px;
		}
	}
	.race-title {
		padding-right: 60px;
		padding-left: 60px;
		text-align: center;
	}
}