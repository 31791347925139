@import 'variables';

.home-wrapper {
	background-size: cover;
	background-repeat: no-repeat;
}
.home {
	background-color: $primary;
	color: rgba(255, 255, 255, 1);
	display: grid;
	grid-gap: 10px;
	justify-items: center;
	align-content: center;
	height: calc(var(--vh, 1vh) * 100);
	max-height: calc(var(--vh, 1vh) * 100);
	text-align: center;
	position: relative;
}
.home-title {
	font-family: $custom-font-family;
	font-size: 3em;
	font-weight: 500;
	letter-spacing: -1px;
}
.home-subtitle {
	color: $secondary;
	padding-bottom: 30px;
}
.logo-home {
	height: 35px;
}
.home-cta {
	font-size: 1.4em;
	font-weight: 600;
	background-color: rgba(255, 255, 255, 1);
	padding: 15px;
	padding-right: 20px;
	padding-left: 20px;
	color: $cta-color;
	border-radius: 15px;
	cursor: pointer;
	width: min-content;
	height: min-content;
	white-space: nowrap;
}
.home-cta:hover {
	background-color: $secondary;
}
// mobile-style
@media screen and (max-width: 768px) {
	.home-title {
		font-size: 2.4em;
	}
	.home-subtitle {
		padding-top: 0px;
		padding-right: 60px;
		padding-left: 60px;
	}
	.logo-home {
		width: calc(100% - 40px);
	}
}