.go-button {
	background-color: rgba(233, 12, 131, 1);
	color: rgba(255, 255, 255, 1);
	display: grid;
	width: 90px;
	height: 90px;
	padding: 10px;
	justify-self: center;
	align-content: center;
	border-radius: 50%;
	cursor: pointer;
	border: 2px solid rgba(208, 208, 208, 1);
	font-weight: 700;
	font-size: 2.4em;
}
.go-button:hover {
	background-color: rgba(255, 255, 255, 1);
	color: rgba(233, 12, 131, 1);
	border: 2px solid rgba(233, 12, 131, 1);
}