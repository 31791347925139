.questions-done-button {
	background-color: rgba(255, 255, 255, 1);
	color: rgba(233, 12, 131, 1);
	border-radius: 5px;
	cursor: pointer;
	padding: 5px;
	font-size: 1.4em;
	font-weight: 700;
	border: 1px solid rgba(233, 12, 131, 1);
	padding-right: 20px;
	padding-left: 20px;
}
.questions-done-button:hover {
	background-color: rgba(233, 12, 131, 0.8);
	color: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 768px) {
	.questions-done-button {
		font-size: 1.2em;
		padding: 2px;
		padding-left: 10px;
		padding-right: 10px;
		
	}
}