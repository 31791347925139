.basemaps-wrapper {
  position: absolute;
  bottom: 30px;
  right: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  display: grid;
  grid-template-columns: min-content 32px;
  grid-gap: 10px;
  color: rgba(233, 12, 131, 1);
  font-size: 16px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .12); 
}
.switch-wrapper {
  position: relative;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  top: 3px;
  left: 3px;
  background-color: rgba(233, 12, 131, 1);
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: rgba(233, 12, 131, 1);
}
input:checked + .slider:before {
  background-color: rgba(255, 255, 255, 1);
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 10px;
  background-color: rgba(199, 212, 211, 1);
}
.slider.round:before {
  border-radius: 10px;
}
.switch .labels {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
}
// mobile-style
@media screen and (max-width: 768px) {
  .basemaps-wrapper {
    bottom: 5px;
    left: 100px;
    right: auto;
    top: auto;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
  } 
}