.arrow-wrapper {
	padding-bottom: 20px;
	padding-right: 40px;
	padding-left: 40px;
}
.arrows-wrapper {
	display: flex; 
	width: 100%;
}
.arrow-text {
	color: rgba(233, 12, 131, 0.6); 
	text-align: center;
}
@media screen and (max-width: 768px) {
	.arrow-wrapper {
		padding-bottom: 0px;
		padding-top: 10px;
	}
	.arrow-text {
		display: none;
	}
}