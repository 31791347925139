.maps-go-circle {
    background-color: rgba(233, 12, 131, 0.8);
    color: rgba(255, 255, 255, 1);
    width: 55px;
    height: 55px;
    display: grid;
    border-radius: 50%;
}
.maps-custom-cursor {
    position: fixed;
    pointer-events: none;
    z-index: 20;
}
@media screen and (max-width: 768px) {
  .maps-go-circle {
    display: none;
  }
  .maps-custom-cursor {
      display: none;
  }
}