.page2-cta {
	font-size: 1.4em;
	font-weight: 600;
	background-color: rgba(255, 255, 255, 1);
	color: rgba(233, 12, 131, 1);
	border-radius: 50%;
	width: 110px;
	height: 110px;
	display: grid;
	align-items: center;
	text-align: center;
	border: 1px solid rgba(233, 12, 131, 0.4);
	cursor: pointer;
}
.page2-cta:hover {
	background-color: rgba(233, 12, 131, 1);
	color: rgba(255, 255, 255, 1);
	border: 1px solid rgba(201, 206, 218, 0.4);
}