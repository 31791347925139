.add-pin-message {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(233, 12, 131, 0.6);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.4em;
    z-index: 21;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    animation: fadeOut 3s ease-in-out forwards;
    width: min-content;
    white-space: nowrap;
    pointer-events: none;
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1; /* Keep it visible for 80% of the time */
    }
    100% {
        opacity: 0;
    }
}