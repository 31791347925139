.recenter-wrapper {
  position: absolute;
  right: 150px;
  bottom: 30px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: grid;
  justify-content: center;
  align-content: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .12); 
}
.recenter-image {
  width: 30px;
}
.recenter-wrapper:hover {
  background-color: rgba(233, 12, 131, 1);
  img {
    filter: brightness(0) invert(1);  
  }
}
@media screen and (max-width: 768px) {
  .recenter-wrapper {
    width: 25px;
    height: 25px;
    right: 10px;
    bottom: 40px;
  }
}