@import 'variables';

.responses-wrapper {
  overflow: hidden;
  background-color: $light-bg;
  position: relative;
  display: grid;
  grid-template-rows: min-content auto 80px;
}
.responses-header {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
  display: grid;
  gap: 5px;
}
.responses-header-title {
  color: $primary;
  font-size: 1.6em;
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .responses-header-title {
    font-size: 1em;
  }
}