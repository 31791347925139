@import 'variables';

.vision-wrapper {
	display: grid;
	color: $light-gray;
	background-color: $light-bg;
	text-align: center;
	font-size: 1.2em;
	align-content: center;
	grid-gap: 40px;
}
@media screen and (max-width: 768px) {
	.vision-wrapper {
		grid-gap: 20px;
	}
}