.maps-search-wrapper {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  right: 10px;
  top: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .12); 
  padding-left: 10px;
  padding-right: 10px;
  z-index: 20;
}
.maps-search {
  display: grid;
  height: 30px;
  grid-template-columns: min-content auto min-content;
  position: relative;
  border-radius: 5px;
  align-items: center;
  padding-top: 3px;
}
.maps-input {
  border: none;
  padding-left: 10px;
  font-size: 16px
}
.maps-input::placeholder {
  opacity: 0.4;
  font-style: italic;
}
.maps-input:focus {
  outline: none;
}
// mobile-style
@media screen and (max-width: 768px) {
  .maps-search-wrapper {
    left: 10px;
    right: auto;
  } 
}