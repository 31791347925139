.world-cta {
	white-space: nowrap;
	position: absolute;
	bottom: 35px;
	right: 20px;
	color: rgba(233, 12, 131, 1);
	background-color: rgba(255, 255, 255, 1);
	width: min-content;
	display: grid;
	justify-content: center;
	align-content: center;
	border-radius: 5px;
	border: 2px solid rgba(233, 12, 131, 1);
	font-weight: 700;
	font-size: 1.2em;
	padding: 5px;
	padding-right: 20px;
	padding-left: 20px;
	cursor: pointer;
}
.world-cta:hover {
	color: rgba(255, 255, 255, 1);
	background-color: rgba(233, 12, 131, 1);
	border: 2px solid rgba(208, 208, 208, 1);
}