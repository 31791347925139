.maps-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: min-content min-content auto;
  overflow: hidden;
  grid-template-areas:
    "he mp"
    "to mp"
    "le mp";
}
.questions-wrapper {
  grid-area: le;
}
.maps-header {
  grid-area: he;
}
.topics-wrapper {
  grid-area: to;
} 
.map-container {
  grid-area: mp;
}
// mobile-style
@media screen and (max-width: 768px) {
  .maps-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 2fr 1fr min-content;
    grid-template-areas:
    "he"
    "mp"
    "le"
    "to";
  }
}