.race-dropdown-wrapper {
  background-color: none;
  width: 300px;
  cursor: pointer;
}
.race-suggestions {
  text-indent: 20px;
  position: absolute;
  list-style-type: none;
  padding: 0;
  padding-top: 10px;
  margin: 0;
  width: 100%;
  font-size: .875rem;
  color: rgba(112, 112, 112, 0.6);
  box-shadow: 1px 1px 1px 1px rgba(112, 112, 112, 0.2);
  border: 1px solid rgba(112, 112, 112, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  li {
    padding: 2px;
  }
  li:hover {
    background-color: rgba(236, 236, 236, 1);
  }
}
.race-maps-input {
  text-indent: 10px;
  padding: 15px;
  padding-left: 30px;
  box-shadow: 1px 1px 1px 1px rgba(112, 112, 112, 0.2);
  border: 1px solid rgba(112, 112, 112, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  color: rgba(112, 112, 112, 1);
  font-weight: 500;
  margin-bottom: 5px;
}
.race-drop-button-img {
  position: absolute;
  right: 30px;
  top: 17.5px;
  width: 10px;
}