.topics-wrapper {
  padding: 40px;
  padding-bottom: 0;
  background-color: rgba(243, 243, 243, 1);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.topics-image {
  border: 1px solid rgba(208, 208, 208, 1);
  cursor: pointer;
  border-radius: 50%;
  justify-self: center;
  width: 45px;
  height: 45px;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  img {
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 12.5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img:hover {
    filter: brightness(0) invert(1);
  }

}
.topics-image:hover {
  background-color: rgba(233, 12, 131, 1);
}
@media screen and (max-width: 768px) {
  .topics-wrapper {
    padding-top: 5px;
    padding-bottom: 10px;
  } 
  .topics-image {
    width: 40px;
    height: 40px;
    img {
      padding: 10px;
    }
  }
}