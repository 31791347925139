.question-arrow {
	cursor: pointer;
	transition: transform 0.3s ease;
}
.question-circle {
	stroke: rgba(233, 12, 131, 1);
	stroke-width: 0.1;
	fill: rgba(255, 255, 255, 1);
}
.question-lines {
	stroke: rgba(233, 12, 131, 1);
	stroke-width: 1.2;
	fill: none;
	pointer-events: none;
}
.question-arrow:hover {
	.question-circle {
		fill: rgba(233, 12, 131, 1);
	}	
	.question-lines {
		stroke: rgba(255, 255, 255, 1);
	}
}
// mobile-style
@media screen and (max-width: 600px) {
	.question-arrow {
		display: none;
	}

}