.vision-list {
	display: flex;
	gap: 10px;
	font-weight: 600;
	align-content: center;
	justify-content: center;
}
.vision-image-wrapper {
	border-radius: 50%;
	width: 110px;
	height: 110px;
	position: relative;
	img	{
		position: absolute;
		width: 50px;
		height: 50px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
@media screen and (max-width: 1000px) {
	.vision-list {
		gap: 0px;
	}
	.vision-image-wrapper {
		justify-self: center;
		width: 70px;
		height: 70px;
	}
}