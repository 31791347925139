.result-done-button {
	justify-self: center;
	padding-top: 20px;
	padding-bottom: 20px;
}
.finish-button {
	background-color: rgba(255, 255, 255, 1);
	width: min-content;
	border-radius: 5px;
	cursor: pointer;
	color: rgba(233, 12, 131, 1);
	padding: 5px;
	padding-right: 20px;
	padding-left: 20px;
	font-size: 1.4em;
	font-weight: 700;
	border: 1px solid rgba(233, 12, 131, 1);
}
.finish-button:hover {
	background-color: rgba(233, 12, 131, 0.8);
	color: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 1000px) {
	.result-done-button {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.finish-button {
		bottom: 0px;
	}
}