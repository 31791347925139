.intro-subtitle {
	color: rgba(252, 175, 233, 1);
	text-align: center;
	font-size: 1.4em;
}
@media screen and (max-width: 1000px) {
	.intro-subtitle {
		padding-left: 20px;
		padding-right: 20px;
	}
	.desktop-only {
		display: none;
	}
}