.questions-wrapper {
	display: grid;
	grid-template-rows: auto min-content;
	background-color: rgba(243, 243, 243, 1);
}
.questions {
	display: grid;
	align-content: center;
	grid-gap: 40px;
	font-family: 'ITC Avant Garde Pro Md', sans-serif;
}
.questions-title {
	color: rgba(233, 12, 131, 1);
	font-size: 1.6em;
	text-align: center;
	padding-right: 40px;
	padding-left: 40px;
}
.questions-template {
	color: rgba(233, 12, 131, 0.6);
	font-size: 1em;
	text-align: center;
	padding-right: 20px;
	padding-left: 20px;
	padding-bottom: 5px;
}

@media screen and (max-width: 600px) {
	.questions {
		align-content: start;
		padding-top: 10px;
		height: calc(100% - 10px);
		grid-gap: 10px;
	}
	.questions-template {
		font-size: 0.8em;
		padding-top: 3px;
	}
	.questions-title {
		padding-right: 30px;
		padding-left: 30px;
		font-size: 1em;
	}
}