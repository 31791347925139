body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color:transparent;
}
.App {
  height: 100vh;
  overflow: hidden;
  display: grid;
  max-height: calc(var(--vh, 1vh) * 100);
}