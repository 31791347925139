.results-selector {
	display: grid;
	padding-left: 40px;
	padding-right: 40px;
	grid-gap: 10px;
	align-content: center;
}
.results-questions-item {
	display: flex;
	height: min-content;
	justify-content: space-between;
	background-color: rgba(255, 255, 255, 1);
	padding: 5px;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid rgba(126, 126, 132, 0.8);
	border-radius: 10px;
	cursor: pointer;
	color: rgba(126, 126, 132, 1);
}
.results-questions-item:hover {
	background-color: rgba(243, 243, 243, 1);
}
@media screen and (max-width: 1000px) {
	.results-selector {
		grid-gap: 5px;
	}	
}