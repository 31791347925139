.world-title-wrapper {
  color: rgba(233, 12, 131, 1);
  position: absolute;
  font-style: italic;
  font-size: 2em;
  font-weight: 700;
  left: 20px;
  top: 20px;
  z-index: 20;
}
@media screen and (max-width: 768px) {
  .world-title-wrapper {
    font-size: 1em;
    font-weight: 600;
  }
}