.responses-cta-wrapper {
	display: flex;
	gap: 60px;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.responses-cta {
	background-color: rgba(233, 12, 131, 1);
	color: rgba(255, 255, 255, 1);
	border-radius: 10px;
	width: min-content;
	height: min-content;
	cursor: pointer;
	white-space: nowrap;
	padding: 10px;
	padding-right: 60px;
	padding-left: 60px;
	font-size: 0.8em;
	border: 1px solid rgba(233, 12, 131, 1);
	font-weight: 600;

}
.responses-cta:hover {
	background-color: rgba(255, 255, 255, 1);
	color: rgba(233, 12, 131, 1);
}
.new-response-wrapper {
	position: relative
}
.options-cta-wrapper {
	position: absolute; 
	top: 60px; 
	left: 0; 
	right: 0; 
	display: grid; 
	grid-gap: 20px;
}
.options-cta {
	background-color: rgba(255, 255, 255, 1);
	cursor: pointer;
	white-space: nowrap;
	padding: 10px;
	font-size: 0.8em;
	border: 1px solid rgba(233, 12, 131, 1);
	font-weight: 600;

}
.options-cta:hover {
	background-color: rgba(233, 12, 131, 1);
	color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 768px) {
	.responses-cta-wrapper {
		gap: 10px;
		justify-content: center;
	}
	.options-cta-wrapper {
		top: 40px; 
		left: 0; 
		right: 0; 
		display: grid; 
		grid-gap: 5px;
	}
	.responses-cta {
		width: auto;
		padding-right: 20px;
		padding-left: 20px;
	}
	.new-response-wrapper {
		grid-row: 2;
	}
}