.slider-wrapper-wrapper {
	display: grid; 
	padding-right: 40px; 
	padding-left: 40px;
	color: rgba(126, 126, 132, 1); 
}
.slider-wrapper {
	width: 100%; 
	height: 30px;
	cursor: ew-resize;
}
.slider-legend-wrapper {
	display: flex; 
	justify-content: space-between; 
	font-size: 0.8em;
}