@import 'variables';

.world-wrapper {
	margin: 0;
	padding: 0;
	position: relative;
}
.cursor-hidden {
	.mapboxgl-canvas {
		cursor: none !important;
	}
}
.go-circle {
    background-color: $primary;
    color: rgba(255, 255, 255, 1);
    width: 90px;
    height: 90px;
    display: grid;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 2em;
}
.custom-cursor {
    position: fixed;
    pointer-events: none; /* So it doesn't block clicks */
    z-index: 20;
}
@media screen and (max-width: 768px) {
	.go-circle {
		background-color: $primary;
		width: 60px;
		height: 60px;
		font-size: 1.4em;
	}
}