.world-search-wrapper {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  right: 20px;
  top: 30px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .12); 
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
}
.world-search {
  display: grid;
  height: 30px;
  grid-template-columns: min-content auto min-content;
  position: relative;
  border-radius: 5px;
  align-items: center;
  padding-top: 3px;
}
.world-search-input {
  border: none;
  padding-left: 10px;
  font-size: 16px
}
.world-search-input::placeholder {
  opacity: 0.4;
  font-style: italic;
}
.world-search-input:focus {
  outline: none;
}
// mobile-style
@media screen and (max-width: 1000px) {
  .world-search-wrapper {
    top: 60px;
    left: 20px;
    right: auto;
  }

}