@import 'variables';

.gender {
	display: grid;
	justify-items: center;
	align-content: center;
	grid-gap: 60px;
	height: calc(var(--vh, 1vh) * 100);
	max-height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;
	color: $light-gray;
	background-color: $light-bg;
}
.gender-title {
	color: $light-gray;
	font-size: 1.2em;
	font-weight: 600;
}
.gender-items {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	text-align: center;
	font-weight: 700;
	font-size: 1.4em;
	div	{
		background-color: rgba(255, 255, 255, 1);
		padding: 15px;
		border-radius: 15px;
		border: $light-border;
		cursor: pointer;
	}
	div:hover {
		color: rgba(255, 255, 255, 1);
		background-color: $primary;
	}
}
// mobile-style
@media screen and (max-width: 768px) {
	.gender-items {
		grid-template-columns: 1fr;
		font-size: 1.4em;
		div	{
			padding: 10px;
			border-radius: 10px;
		}
	}
}