.maps-title-wrapper {
  color: rgba(233, 12, 131, 1);
  position: absolute;
  font-style: italic;
  font-size: 1.4em;
  font-weight: 700;
  left: 10px;
  top: 15px;
  z-index: 20;
}
@media screen and (max-width: 768px) {
  .maps-title-wrapper {
    display: none;
  }
}