@import 'variables';

.ending-wrapper {
	background-size: cover;
	background-repeat: no-repeat;
}
.ending {
	background-color: $primary;
	color: rgba(255, 255, 255, 1);
	display: grid;
	grid-template-rows: 3fr 1fr min-content;
	grid-gap: 10px;
	justify-items: center;
	height: calc(var(--vh, 1vh) * 100);
	max-height: calc(var(--vh, 1vh) * 100);
	text-align: center;
}
.ending-title {
	font-family: $custom-font-family;
	font-size: 3em;
	font-weight: 500;
	letter-spacing: -1px;
}
.ending-subtitle {
	color: $secondary;
	padding-bottom: 30px;
}
.logo-ending {
	height: 40px;
}
.ending-cta {
	font-size: 1.4em;
	font-weight: 600;
	background-color: rgba(255, 255, 255, 1);
	padding: 15px;
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 15px;
	cursor: pointer;
	width: min-content;
	height: min-content;
	white-space: nowrap;
	color: $cta-color;
}
.ending-cta:hover {
	background-color: $light-bg;
}
.thanks-message {
	font-size: 1.6em;
	padding-bottom: 30px;
	font-weight: 700;
	letter-spacing: -1px;
}
.ending-top {
	align-self: center;
}
// mobile-style
@media screen and (max-width: 768px) {
	.ending-title {
		font-size: 2.4em;
	}
	.ending-subtitle {
		padding-top: 0px;
		padding-right: 60px;
		padding-left: 60px;
	}
	.logo-ending {
		width: calc(100% - 40px);
	}
}