.ranking-wrapper {
	display: flex; 
	white-space: nowrap;
	color: rgba(126, 126, 132, 1);
	padding-bottom: 5px;
	div::first-letter {
	    text-transform: uppercase;
	}
}
.ranking-title {
	font-size: 1.2em; 
	font-weight: 600; 
	color: rgba(126, 126, 132, 0.8); 
	padding-bottom: 10px;
}