.add-pin {
	background-color: rgba(255, 255, 255, 1);
	margin: auto;
	white-space: nowrap;
	padding: 5px;
	padding-right: 10px;
	padding-left: 10px;
	border-radius: 5px;
	color: rgba(97, 135, 218, 1);
	border: 0.1px solid rgba(97, 135, 218, 1);
	display: flex;
	gap: 5px;
	cursor: pointer;
	font-size: 1.2em;
	margin: auto;
}
.add-pin-plus {
	stroke: rgba(97, 135, 218, 1);
	stroke-width: 1.8;
}
.add-pin:hover {
	background-color: rgba(233, 12, 131, 1);
	color: rgba(255, 255, 255, 1);
	border: 0.1px solid rgba(255, 255, 255, 1);
	.add-pin-plus {
		stroke: rgba(255, 255, 255, 1);
	}
}
@media screen and (max-width: 600px) {
	.add-pin {
		display: none;
		font-size: 1em;
	}
}