.search-suggestions {
  position: absolute;
  top: 37px;
  left: -10px;
  list-style-type: none;
  padding: 10px;
  margin: 0;
  width: calc(100%);
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .12); 
  cursor: pointer;
}
.search-suggestions {
  li:hover{
    background-color: rgba(235, 235, 235, 1);
    border-radius: 10px;
  }
}
.current-suggestion {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 10px;
  overflow: hidden;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}