.delete-pin-wrapper {
	position: absolute;
	right: 10px;
	bottom: calc(50% - 70px);
	background-color: rgba(255, 255, 255, 1);
	width: 55px;
	height: 55px;
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 2px 2px 2px rgba(64, 64, 64, .12);
	border: 1px solid rgba(233, 12, 131, 1); 
}
.delete-pin-wrapper:hover {
	background-color: rgba(233, 12, 131, 1);
	border: 1px solid rgba(255, 255, 255, 1); 
	img {
		filter: brightness(0) invert(1);	
	}
}
.delete-pin-image {
	position: absolute;
	height: 30px;
	left: 17px;
	top: 12px;
}