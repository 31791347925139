.logo-unoun {
	height: 30px;
}
.home-bottom {
	width: min-content;
	white-space: nowrap;
	position: absolute;
	left: 50%;
	bottom: 20px;
	display: grid;
	grid-gap: 7px;
	color: rgba(255, 255, 255, 0.6);
	text-align: center;
	justify-self: center;
}