.statement-wrapper {
	display: grid;
	grid-gap: 30px;
}
.statement-title {
	font-size: 1.6em;
	font-weight: 600;
}
.vision-paragraph {
	color: rgba(232, 176, 214, 1);
	padding-right: 25%;
	padding-left: 25%;
}
// mobile-style
@media screen and (max-width: 1000px) {
	.statement-wrapper {
		grid-gap: 10px;
	}
	.statement-title {
		font-size: 1em;
	}
	.vision-paragraph {
		padding-right: 20px;
		padding-left: 20px;
		font-size: 0.8em;
	}
}