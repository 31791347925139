@import 'variables';

.intro {
	display: grid;
	justify-items: center;
	align-content: center;
	grid-gap: 20px;
	height: calc(var(--vh, 1vh) * 100);
	max-height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;
	color: $primary;
	background-color: $light-bg;
}
.intro-title {
	font-size: 3em;
	font-weight: 700;
	font-style: italic;
}